import slugify from '@sindresorhus/slugify'
import * as React from 'react'
import { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
  desc?: string
  descId?: string
}
const SvgCampaignEntity = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  if (!titleId && title) titleId = slugify(title)

  if (!descId && desc) descId = slugify(desc)

  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className={props.className}
      aria-labelledby={titleId}
      aria-describedby={descId}
      {...props}>
      {desc && <desc id={descId}>{desc}</desc>}
      {title && <title id={titleId}>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.042 13.168h-5.311V4.281A2.275 2.275 0 0 0 30.465 2H9.295A2.275 2.275 0 0 0 7.03 4.28v8.81H1.953A1.94 1.94 0 0 0 0 15.056v19.978A1.94 1.94 0 0 0 1.953 37h36.09a1.94 1.94 0 0 0 1.952-1.966v-19.9c.079-1.1-.859-1.966-1.953-1.966ZM7.03 35.507H1.953c-.234 0-.39-.236-.39-.472v-19.9c0-.235.156-.392.39-.392H7.03v20.764Zm16.795 0H16.17v-11.72h7.655v11.72Zm7.343 0h-5.78V22.213h-10.78v13.293H8.593V4.28c0-.393.312-.708.703-.708h21.17c.39 0 .702.315.702.708v31.225Zm7.343-.472c0 .236-.156.393-.39.393h-5.312V14.742h5.312c.234 0 .39.157.39.393v19.899ZM24.528 11.83c0 .394-.39.787-.78.787H20.7v3.067c0 .394-.39.787-.781.787a.786.786 0 0 1-.782-.787v-3.067h-3.046c-.39 0-.781-.393-.781-.787 0-.393.39-.786.78-.786h3.047V7.978c0-.394.313-.787.782-.787.39 0 .78.393.78.787v3.067h3.047c.469 0 .782.393.782.787Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgCampaignEntity
