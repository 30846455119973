import slugify from '@sindresorhus/slugify'
import * as React from 'react'
import { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
  desc?: string
  descId?: string
}
const SvgCircleMessageCheck = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  if (!titleId && title) titleId = slugify(title)

  if (!descId && desc) descId = slugify(desc)
  return (
    <svg
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className={props.className}
      data-testid="circle-message-check"
      aria-labelledby={titleId}
      aria-describedby={descId}
      {...props}>
      {desc && <desc id={descId}>{desc}</desc>}
      {title && <title id={titleId}>{title}</title>}
      <rect width={160} height={160} rx={80} fill="#EBF4F6" />
      <g clipPath="url(#circle-message-check_svg__a)" fill="#18808C">
        <path d="M94.125 62.875a2.988 2.988 0 0 1 0 4.237l-16 16.013a2.988 2.988 0 0 1-4.238 0l-8-8a2.988 2.988 0 0 1 0-4.238c1.175-1.162 3.075-1.175 4.238 0L76 76.762l13.875-13.887a2.988 2.988 0 0 1 4.237 0h.013Z" />
        <path
          opacity={0.4}
          d="M48 56c0-4.413 3.587-8 8-8h48c4.412 0 8 3.587 8 8v36c0 4.412-3.588 8-8 8H86.662L71.2 111.6c-.6.45-1.413.525-2.1.187A1.966 1.966 0 0 1 68 110v-10H56c-4.413 0-8-3.588-8-8V56Zm46.125 11.125a2.988 2.988 0 0 0 0-4.237c-1.175-1.163-3.075-1.175-4.237 0L76.011 76.763l-5.874-5.876a2.988 2.988 0 0 0-4.238 0c-1.163 1.175-1.175 3.076 0 4.238l8 8a2.988 2.988 0 0 0 4.237 0l15.988-16Z"
        />
      </g>
      <defs>
        <clipPath id="circle-message-check_svg__a">
          <path fill="#fff" transform="translate(48 48)" d="M0 0h64v64H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgCircleMessageCheck
