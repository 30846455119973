import slugify from '@sindresorhus/slugify'
import * as React from 'react'
import { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
  desc?: string
  descId?: string
}
const SvgCreateAccount = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  if (!titleId && title) titleId = slugify(title)

  if (!descId && desc) descId = slugify(desc)
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className={props.className}
      aria-labelledby={titleId}
      aria-describedby={descId}
      {...props}>
      {desc ? <desc id={descId}>{desc}</desc> : null}
      {title ? <title id={titleId}>{title}</title> : null}
      <mask
        id="Group_svg__a"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={72}
        height={72}>
        <path
          d="M36 72c19.882 0 36-16.118 36-36S55.882 0 36 0 0 16.118 0 36s16.118 36 36 36z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#Group_svg__a)">
        <path d="m-60.459-10.048-.61 109.728 141.88.79.611-109.729-141.88-.79z" fill="#C1DDE1" />
        <path
          d="M28.576 38.6c.917.187 2.3-.511 3.374-1.022 1.382-.7 2.727-1.36 3.23-2.645.594-1.526-.78-2.12-.803-4.411-.015-2.938 2.171-5.373 2.908-6.162 1.202-1.337 1.533-1.06 2.156-2.096 1.45-2.314.73-5.14.496-6.147-.3-1.224-.751-2.908-2.33-4.178a6.16 6.16 0 0 0-3.02-1.24s-2.72-.352-5.673 1.774c-6.8 4.914-4.561 25.277-.338 26.127z"
          fill="#1C1D1F"
        />
        <path
          d="M24.518 36.007c1.66 4.08 6.049 7.747 7.063 5.914.436-.76-.759-1.503-1.232-4.35-.323-1.962-.158-4.043.368-5.05.09-.248.278-.429.526-.549h.06c.429-.12.639.368 1.526.654.946.278 2.382.383 2.787-.263.248-.338-.06-.579.128-1.225.158-.428.436-.827.804-1.097.09-.09.218-.21.278-.338.09-.12.188-.24.248-.398-.18-.188-.368-.248-.338-.338.03-.09.248-.09.458-.12.03-.249.03-.52.008-.767-.03-.308.068-.579.278-.827.616-.729 1.623-.36 1.901-.939.218-.488-.42-.917-.782-1.901-.51-1.285.046-1.931.083-4.253.038-1.195.045-2.142-.255-3.186-.421-1.413-1.48-3.163-3.036-3.937-2.442-1.24-5.786.15-7.724 1.795-3.75 3.164-1.691 6.756-5.23 16.284-1.684 4.53 1.33 3.02 2.081 4.891z"
          fill="#B3713C"
        />
        <path
          d="M35.947 22.58c0 .218.18.398.429.398a.4.4 0 0 0 .398-.398.4.4 0 0 0-.398-.399c-.21 0-.399.18-.429.399z"
          fill="#50280F"
        />
        <path
          d="M35.955 21.685c.518-.24 1.097.346 1.157.406.27.248.428.556.42.917-.03.03-.12-.278-.428-.489-.608-.496-1.435-.157-1.525-.405-.03-.15.188-.369.376-.429zm.217-1.705.556-.27a.844.844 0 0 1 .887.067l1.247.834-.218.608-1.307-.894c-.12-.09-.278-.12-.428-.157l-.737-.068c-.03 0-.06-.06-.06-.12h.06zm2.11 6.43c0-.12-.164-.218-.367-.22-.203 0-.369.096-.37.217 0 .12.164.218.368.22.203 0 .368-.096.369-.216z"
          fill="#50280F"
        />
        <path
          d="M35.82 29.402c.21.248.488.459.758.647.24.157.489.218.609.127.12-.09.128-.368.098-.518a.74.74 0 0 0-.18-.338c-.18-.188-.369-.218-.339-.338.03-.12.248-.09.459-.12.18 0 .398-.09.518-.241a.42.42 0 0 0-.06-.488c-.09-.09-.248-.09-.428-.03-.218.06-.429.09-.647.12-.428.06-.856.06-1.284-.008-.293-.015.248.91.495 1.187z"
          fill="#B8493A"
        />
        <path
          d="M29.447 30.65c.33.676.76 1.322 1.278 1.9.09-.247.278-.427.526-.548a10.084 10.084 0 0 1-1.158-1.382c-1.089-1.66-1.33-3.224-1.45-3.194-.127.038.045 1.6.804 3.224z"
          fill="#7A481E"
        />
        <path
          d="m50.818 50.412-.24-.308a.431.431 0 0 1 .067-.609l14.074-11.399a.431.431 0 0 1 .608.068l.24.308a.43.43 0 0 1-.067.608l-14.081 11.4a.424.424 0 0 1-.601-.068z"
          fill="#161647"
        />
        <path
          d="M54.77 55.333c1.443-1.029 1.202-1.825 3.201-3.81 1.6-1.585 2.21-1.547 2.555-2.524.653-1.864-.864-3.892-1.263-4.41-1.157-1.571-3.013-2.683-3.441-2.315-.308.27.33 1.074.503 2.42.173 1.374-.293 2.66-1.495 4.703-2.72 4.606-5.207 6.184-4.749 6.92.39.677 2.9.286 4.689-.984zm-10.031-2.231c-.61-.676.811-2.87 1.893-4.516 1.57-2.412 3.239-3.96 5.448-5.966 1.72-1.578 3.381-2.886 3.471-2.795.15.187-4.035 4.23-3.727 4.568.18.218 2.03-1.488 2.548-1.06.458.369.045 2.36-1.24 3.42-1.045.879-1.991.6-2.608 1.577-.465.7-.157 1.195-.405 1.984-.662 2.262-4.584 3.652-5.38 2.788z"
          fill="#B3713C"
        />
        <path
          d="M16.959 74.615c.203 1.803.586 4.59 2.57 6.161 1.916 1.51 4.613 1.097 9.91.18h.061c5.358-.916 10.324-2.299 9.197-3.47-.909-.925-2.742-1.917-3.84-3.142-.488-.586-.969-1.134-1.337-1.57.759.864 1.646 1.63 2.555 2.337h.03a9.929 9.929 0 0 0 2.87 1.487h.03a5.58 5.58 0 0 0 2.142.226c.09 0 .18-.03.248-.03 3.366-.564 3.546-4.787 9.978-11.422.279-.308 3.698-3.802 3.833-6.371 0-.429.067-.887.157-1.285.06-.21.128-.429.188-.64.09-.247.218-.487.338-.728.399-.669.767-.887.865-1.435.225-1.465-1.939-3.712-3.562-3.45-1.473.271-1.698 2.443-2.953 2.368-.488-.03-.609-.338-2.465-1.939-.947-.804-1.427-1.232-1.615-1.262-.489-.128-1.834-.136-5.666 5.658-2.126 3.231-3.028 5.245-3.396 5.125-.519-.218 2.134-4.546.909-9.355-.842-3.307-2.615-3.322-4.088-8.1-.6-1.992-.593-2.969-1.6-3.99-1.458-1.481-2.63-.654-4.854-2.202-2.074-1.42-2.675-3.231-3.78-2.99-.616.15-.954.848-1.074 1.126-.03.03-.03.06-.06.09-4.336 8.942-4.231 6.86-5.23 10.588-2.863 10.76-1.233 20.04-.361 28.035z"
          fill="#FAA819"
        />
        <path
          d="M19.664 56.393C19.837 57.678 22.49 65.831 30.38 72c1.098.864 3.54 2.314 4.637 3.088h.03a27.736 27.736 0 0 0 3.02 1.488 6.114 6.114 0 0 0 2.232.225c.09 0 .18-.03.278-.03-3.381-2.344-17.575-9.738-19.837-20.303-.3-1.345-1.157-6.372-1.743-6.342-.218.03-.406.857-.406 1.496.022 1.457 1.074 4.68 1.074 4.771z"
          fill="#D78F28"
        />
        <path
          d="M16.959 74.615c.203 1.803.556 4.59 2.57 6.161 1.916 1.54 4.613 1.097 9.91.18-1.269-2.457-4.68-5.627-3.756-6.695.398-.488 1.104-.052 2.179-.75 1.473-.94 2.314-3.142 1.736-4.487a2.67 2.67 0 0 0-1.398-1.262c-3.937-1.946-5.936-10.527-6.597-13.285-1.135-4.81-1.886-7.995-.218-10.527.646-1.007 1.412-1.458 1.818-2.863.466-1.713.113-3.825-.676-5.087-4.336 8.941-4.23 6.86-5.23 10.587-2.84 10.783-1.21 20.033-.338 28.028zM49.45 53.68a61.606 61.606 0 0 1-3.983 5.944c-2.772 3.75-4.223 5.605-5.876 5.628-2.021.023-3.539-2.224-4.869-4.185-1.946-2.856-2.63-6.042-3.043-7.973-.18-.856-1.127-6.184-.639-6.274.218-.06.519.428.88 1.105 2.148 3.93 3.238 5.89 3.2 8.092-.007.857.083 1.713.076 2.39a6.891 6.891 0 0 0 1.352 4.26c.85 1.105 1.338 1.202 1.616 1.233.916.097 1.683-.511 3.223-1.729 1.751-1.397 2.615-2.585 4.434-4.809 1.172-1.435 2.554-3.103 3.298-3.9l.33.218zm4.027 3.021a5.471 5.471 0 0 0 1.804.992c.06-.21.127-.428.188-.639.09-.248.218-.488.338-.729a5.608 5.608 0 0 1-1.067-.375c-1.924-.834-2.833-2.187-3.021-2.037-.188.143.443 1.714 1.758 2.788z"
          fill="#D78F28"
        />
        <path
          d="M12.413 44.386c-.338.18 1.991.007 3.644-.684 1.075-.481 2.24-.97 3.014-2.097.743-1.067.405-1.623 1.051-2.532.774-1.097 1.902-1.247 3.434-1.788.519-.18 4.23-1.57 6.665-4.464.865-1.037 1.97-2.344 2.074-4.147.166-2.322-1.457-2.668-1.533-5.486-.075-2.39 1.007-4.373 1.376-5.042.683-1.247 1.142-1.157 1.54-2.164.586-1.465.631-3.576-.616-4.839-.459-.458-1.007-.676-2.164-1.142-.917-.406-1.894-.654-2.9-.812-.767-.097-3.3-.443-5.388.797-1.751 1.03-1.233 1.984-3.36 3.93-2.118 1.946-2.945 1.24-4.177 2.825-2.036 2.622-1.413 6.507-1.353 6.725.21 1.135.451 1.285.384 2.112-.166 1.683-1.39 2.352-2.75 4.238-1.293 1.826-2.255 3.14-2.044 4.576.293 1.96 2.434 2.216 4.313 5.748.39.706.699 1.473.473 2.27a3.117 3.117 0 0 1-1.683 1.976z"
          fill="#1C1D1F"
        />
      </g>
    </svg>
  )
}

export default SvgCreateAccount
