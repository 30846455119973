import slugify from '@sindresorhus/slugify'
import * as React from 'react'
import { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
  desc?: string
  descId?: string
}
const SvgEnvisionLogo = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  if (!titleId && title) titleId = slugify(title)

  if (!descId && desc) descId = slugify(desc)

  return (
    <svg
      viewBox="0 0 468 78"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className={props.className}
      aria-labelledby={titleId}
      aria-describedby={descId}
      {...props}>
      {desc && <desc id={descId}>{desc}</desc>}
      {title && <title id={titleId}>{title}</title>}
      <defs>
        <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="envision-logo_svg__a">
          <stop stopColor="#71C8C7" offset="0%" />
          <stop stopColor="#71C8C7" stopOpacity={0} offset="100%" />
        </linearGradient>
        <linearGradient
          x1="32.463%"
          y1="117.938%"
          x2="66.577%"
          y2="-19.545%"
          id="envision-logo_svg__b">
          <stop stopColor="#71C8C7" offset="5%" />
          <stop stopColor="#71C8C7" stopOpacity={0.76} offset="28%" />
          <stop stopColor="#71C8C7" stopOpacity={0.14} offset="80%" />
          <stop stopColor="#71C8C7" stopOpacity={0} offset="91%" />
        </linearGradient>
        <linearGradient
          x1="-11.26%"
          y1="88.073%"
          x2="114.541%"
          y2="10.604%"
          id="envision-logo_svg__c">
          <stop stopColor="#71C8C7" offset="0%" />
          <stop stopColor="#71C8C7" stopOpacity={0} offset="90%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="envision-logo_svg__d">
          <stop stopColor="#71C8C7" offset="0%" />
          <stop stopColor="#71C8C7" stopOpacity={0} offset="90%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="envision-logo_svg__e">
          <stop stopColor="#71C8C7" offset="0%" />
          <stop stopColor="#71C8C7" stopOpacity={0} offset="92%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="envision-logo_svg__f">
          <stop stopColor="#71C8C7" offset="0%" />
          <stop stopColor="#71C8C7" stopOpacity={0} offset="91%" />
        </linearGradient>
        <linearGradient
          x1="32.77%"
          y1="-12.005%"
          x2="76.025%"
          y2="151.308%"
          id="envision-logo_svg__g">
          <stop stopColor="#71C8C7" offset="4%" />
          <stop stopColor="#71C8C7" stopOpacity={0.82} offset="16%" />
          <stop stopColor="#71C8C7" stopOpacity={0.47} offset="40%" />
          <stop stopColor="#71C8C7" stopOpacity={0.22} offset="60%" />
          <stop stopColor="#71C8C7" stopOpacity={0.06} offset="76%" />
          <stop stopColor="#71C8C7" stopOpacity={0} offset="84%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="envision-logo_svg__h">
          <stop stopColor="#71C8C7" offset="0%" />
          <stop stopColor="#71C8C7" stopOpacity={0} offset="100%" />
        </linearGradient>
        <linearGradient x1="100.35%" y1="50.071%" x2=".35%" y2="50.071%" id="envision-logo_svg__i">
          <stop stopColor="#FF5300" offset="0%" />
          <stop stopColor="#FB5C00" offset="13%" />
          <stop stopColor="#F27602" offset="35%" />
          <stop stopColor="#E79103" offset="54%" />
          <stop stopColor="#E0C006" stopOpacity={0} offset="100%" />
        </linearGradient>
        <linearGradient
          x1="66.968%"
          y1="109.438%"
          x2="33.174%"
          y2="-16.533%"
          id="envision-logo_svg__j">
          <stop stopColor="#FF5300" offset="0%" />
          <stop stopColor="#FB5C00" offset="14%" />
          <stop stopColor="#F27602" offset="36%" />
          <stop stopColor="#E79103" offset="56%" />
          <stop stopColor="#E0C006" stopOpacity={0} offset="100%" />
        </linearGradient>
        <linearGradient
          x1="106.115%"
          y1="83.76%"
          x2="-27.293%"
          y2="3.623%"
          id="envision-logo_svg__k">
          <stop stopColor="#FF5300" offset="0%" />
          <stop stopColor="#FB5C00" offset="11%" />
          <stop stopColor="#F27602" offset="30%" />
          <stop stopColor="#E79103" offset="46%" />
          <stop stopColor="#E0C006" stopOpacity={0} offset="100%" />
        </linearGradient>
        <linearGradient
          x1="105.137%"
          y1="56.469%"
          x2="-16.965%"
          y2="45.343%"
          id="envision-logo_svg__l">
          <stop stopColor="#FF5300" offset="0%" />
          <stop stopColor="#FB5C00" offset="12%" />
          <stop stopColor="#F27602" offset="31%" />
          <stop stopColor="#E79103" offset="48%" />
          <stop stopColor="#E0C006" stopOpacity={0} offset="100%" />
        </linearGradient>
        <linearGradient
          x1="110.382%"
          y1="43.185%"
          x2="-20.465%"
          y2="54.868%"
          id="envision-logo_svg__m">
          <stop stopColor="#FF5300" offset="0%" />
          <stop stopColor="#FB5C00" offset="13%" />
          <stop stopColor="#F27602" offset="35%" />
          <stop stopColor="#E79103" offset="53%" />
          <stop stopColor="#E0C006" stopOpacity={0} offset="100%" />
        </linearGradient>
        <linearGradient
          x1="109.955%"
          y1="14.24%"
          x2="-26.597%"
          y2="95.425%"
          id="envision-logo_svg__n">
          <stop stopColor="#FF5300" offset="0%" />
          <stop stopColor="#FB5C00" offset="12%" />
          <stop stopColor="#F27602" offset="33%" />
          <stop stopColor="#E79103" offset="51%" />
          <stop stopColor="#E0C006" stopOpacity={0} offset="100%" />
        </linearGradient>
        <linearGradient
          x1="66.151%"
          y1="-6.707%"
          x2="32.738%"
          y2="117.438%"
          id="envision-logo_svg__o">
          <stop stopColor="#FF5300" offset="0%" />
          <stop stopColor="#FD5800" offset="13%" />
          <stop stopColor="#F86601" offset="27%" />
          <stop stopColor="#EF7D02" offset="43%" />
          <stop stopColor="#E79103" offset="53%" />
          <stop stopColor="#E0C006" stopOpacity={0} offset="100%" />
        </linearGradient>
        <linearGradient x1="100.333%" y1="50.068%" x2="0%" y2="50.068%" id="envision-logo_svg__p">
          <stop stopColor="#FF5300" offset="0%" />
          <stop stopColor="#FB5C00" offset="15%" />
          <stop stopColor="#F27602" offset="41%" />
          <stop stopColor="#E79103" offset="63%" />
          <stop stopColor="#E0C006" stopOpacity={0} offset="100%" />
        </linearGradient>
      </defs>
      <g fillRule="nonzero" fill="none">
        <path
          d="m44.85 9.064.942 17.062h-1.625C39.422 14.832 35.36 10.95 24.927 10.95h-3.022v26.65h2.941c5.607 0 9.23-4.745 10.693-11.294h1.283v25.529H35.36c-2.064-8.629-5.346-11.993-11.375-11.993h-2.08v27.625h3.25c10.953 0 18.541-7.507 21.125-16.9h1.722l-.585 18.867H0V67.53h5.168V10.95H0V9.064h44.85ZM109.785 69.433H97.793L68.136 13.63v54.161h4.745v1.625H61.068v-1.625h4.745V10.69h-4.745V9.064h23.806l22.669 43.257V10.69h-4.876V9.064h11.863v1.625h-4.745zM175.679 10.689h-6.728V9.064h13.195v1.625H178.1l-20.784 59.605h-7.247l-21.986-59.605H124.1V9.064h28.21v1.625h-6.126l14.495 43.127zM218.042 9.064v1.625h-4.744V67.79h4.744v1.625h-26.325v-1.625h4.745V10.69h-4.744V9.064zM266.061 59.422c0-7.166-5.346-9.49-15.957-12.512-10.612-3.022-18.363-7.069-18.363-20.085 0-9.669 7.15-18.72 20.443-18.72 7.93 0 12.415 2.681 15.34 2.681 1.478 0 1.901-.601 2.502-1.722h.959l.683 17.42h-1.382c-4.404-7.947-10.27-16.559-19.142-16.559-6.208 0-10.53 3.624-10.53 8.71 0 6.37 5.866 8.531 14.625 10.855 9.571 2.6 20.182 5.866 20.182 20.183 0 12.414-8.45 20.62-21.125 20.62-8.368 0-15.616-2.6-18.037-2.6-1.365 0-1.885.96-2.584 2.6h-1.203l-.86-20.523h1.462c4.322 8.612 12.675 18.704 22.165 18.704 5.054 0 10.822-2.763 10.822-9.052ZM314.194 9.064v1.625h-4.745V67.79h4.745v1.625h-26.276v-1.625h4.745V10.69h-4.745V9.064zM462.93 69.433h-12.074L421.281 13.63v54.161h4.762v1.625h-11.814v-1.625h4.745V10.69h-4.745V9.064h23.79l22.587 43.257V10.69h-4.745V9.064h11.814v1.625h-4.745z"
          fill="#FDF5D9"
        />
        <path
          fill="#C99A3F"
          d="m362.928 3.669 1.885-3.137V23.38l-4.648-15.129zM347.295 8.268l.422-5.282 4.534 2.99 4.534 2.99 2.99 18.298-12.902-13.715zM337.285 23.867l-1.999-4.923-1.998-4.94 5.427.796 5.428.813 10.936 15.226zM352.446 36.023l-19.159 1.17-4.03-3.608-4.013-3.608 5.248-1.56 5.265-1.543zM329.209 44.131l4.046-3.591 19.159 1.251-16.738 9.084-5.248-1.576-5.25-1.577zM337.155 53.898l17.826-6.89-11.001 15.177-5.428.78-5.443.78 2.015-4.924zM356.558 68.912l-4.55 2.974-4.534 2.974-.406-5.281-.39-5.281 12.95-13.667zM364.813 69.692v8.19l-2.145-3.607-2.73-4.599 4.875-15.648zM366.714 3.636 364.813.5v22.848l4.647-15.13zM382.33 8.235l-.406-5.297-4.534 3.006-4.534 2.99-3.006 18.281 12.902-13.699zM392.356 23.835l1.999-4.94 1.999-4.924-5.444.796-5.428.797-10.92 15.226zM377.195 35.99l19.159 1.17 4.03-3.607 4.013-3.624-5.248-1.544-5.265-1.56zM400.416 44.099l-4.03-3.608-19.159 1.268 16.738 9.067 5.249-1.56 5.248-1.576zM392.486 53.849l-17.826-6.874 11.001 15.178 5.428.78 5.444.78-2.032-4.94zM373.084 68.88l4.534 2.974 4.55 2.974.406-5.298.39-5.281-12.968-13.65zM364.813 69.66v8.19l2.16-3.607 2.73-4.6-4.89-15.648z"
        />
        <path
          fill="url(#envision-logo_svg__a)"
          d="M366.714 3.136 364.813 0v22.848l4.647-15.13z"
          transform="translate(0 .5)"
        />
        <path
          fill="url(#envision-logo_svg__b)"
          d="m382.346 7.735-.422-5.298-4.518 3.007-4.534 2.99-2.99 18.281 12.903-13.699z"
          transform="translate(0 .5)"
        />
        <path
          fill="url(#envision-logo_svg__c)"
          d="m392.356 23.335 1.999-4.94 1.999-4.924-5.444.796-5.428.797-10.92 15.226z"
          transform="translate(0 .5)"
        />
        <path
          fill="url(#envision-logo_svg__d)"
          d="m377.195 35.49 19.159 1.17 4.03-3.607 4.013-3.624-5.248-1.544-5.265-1.56z"
          transform="translate(0 .5)"
        />
        <path
          fill="url(#envision-logo_svg__e)"
          d="m400.416 43.599-4.03-3.608-19.159 1.268 16.738 9.067 5.249-1.56 5.248-1.576z"
          transform="translate(0 .5)"
        />
        <path
          fill="url(#envision-logo_svg__f)"
          d="m392.486 53.349-17.826-6.874 11.001 15.178 5.428.78 5.444.78-2.032-4.94z"
          transform="translate(0 .5)"
        />
        <path
          fill="url(#envision-logo_svg__g)"
          d="m373.084 68.38 4.534 2.974 4.55 2.974.406-5.298.39-5.281-12.968-13.65z"
          transform="translate(0 .5)"
        />
        <path
          fill="url(#envision-logo_svg__h)"
          d="M364.813 69.16v8.19l2.16-3.607 2.73-4.6-4.89-15.648z"
          transform="translate(0 .5)"
        />
        <path
          fill="url(#envision-logo_svg__i)"
          d="m362.928 3.169 1.885-3.136V22.88l-4.648-15.129z"
          transform="translate(0 .5)"
        />
        <path
          fill="url(#envision-logo_svg__j)"
          d="m347.295 7.768.422-5.282 4.534 2.99 4.534 2.99 2.99 18.298-12.902-13.715z"
          transform="translate(0 .5)"
        />
        <path
          fill="url(#envision-logo_svg__k)"
          d="m337.285 23.367-1.999-4.923-1.998-4.94 5.427.796 5.428.813 10.936 15.226z"
          transform="translate(0 .5)"
        />
        <path
          fill="url(#envision-logo_svg__l)"
          d="m352.446 35.523-19.159 1.17-4.03-3.608-4.013-3.608 5.248-1.56 5.265-1.543z"
          transform="translate(0 .5)"
        />
        <path
          fill="url(#envision-logo_svg__m)"
          d="m329.209 43.631 4.046-3.591 19.159 1.251-16.738 9.084-5.248-1.576-5.25-1.577z"
          transform="translate(0 .5)"
        />
        <path
          fill="url(#envision-logo_svg__n)"
          d="m337.155 53.398 17.826-6.89-11.001 15.177-5.428.78-5.443.78 2.015-4.924z"
          transform="translate(0 .5)"
        />
        <path
          fill="url(#envision-logo_svg__o)"
          d="m356.558 68.412-4.55 2.974-4.534 2.974-.406-5.281-.39-5.281 12.95-13.667z"
          transform="translate(0 .5)"
        />
        <path
          fill="url(#envision-logo_svg__p)"
          d="M364.813 69.192v8.19l-2.145-3.607-2.73-4.599 4.875-15.648z"
          transform="translate(0 .5)"
        />
      </g>
    </svg>
  )
}

export default SvgEnvisionLogo
