import slugify from '@sindresorhus/slugify'
import * as React from 'react'
import { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
  desc?: string
  descId?: string
}
const SvgFundingProgress = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  if (!titleId && title) titleId = slugify(title)

  if (!descId && desc) descId = slugify(desc)

  return (
    <svg
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className={props.className}
      aria-labelledby={titleId}
      aria-describedby={descId}
      {...props}>
      {desc && <desc id={descId}>{desc}</desc>}
      {title && <title id={titleId}>{title}</title>}
      <g clipPath="url(#funding-progress_svg__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.702 34.103c-.671 0-1.343.227-2.015.453L21.941 25.6c.112-.454.224-.794.224-1.248 0-2.607-2.015-4.648-4.59-4.648-2.463 0-4.59 2.04-4.59 4.648 0 1.02.336 1.928.784 2.608L6.94 34.783c-.671-.453-1.455-.68-2.35-.68-2.575 0-4.59 2.04-4.59 4.648C0 41.36 2.015 43.4 4.59 43.4c2.574 0 4.59-2.04 4.59-4.648 0-.908-.225-1.701-.672-2.382l6.94-7.936c.672.34 1.343.567 2.015.567a4.525 4.525 0 0 0 3.246-1.36l10.187 8.39c-.56.793-.895 1.7-.895 2.72 0 2.608 2.015 4.649 4.59 4.649 2.574 0 4.589-2.04 4.589-4.648.112-2.608-1.903-4.649-4.478-4.649Zm-30 7.03c-1.344 0-2.351-1.021-2.351-2.382 0-1.36 1.007-2.38 2.35-2.38.672 0 1.232.226 1.68.68l.112.113c.335.454.56 1.02.56 1.587 0 1.248-1.12 2.381-2.351 2.381Zm12.873-14.4a2.38 2.38 0 0 1-2.35-2.38c0-1.361 1.007-2.382 2.35-2.382 1.343 0 2.35 1.02 2.35 2.381 0 1.248-1.007 2.381-2.35 2.381Zm17.127 14.4c-1.343 0-2.35-1.021-2.35-2.382 0-1.36 1.007-2.38 2.35-2.38 1.344 0 2.351 1.02 2.351 2.38 0 1.248-1.007 2.381-2.35 2.381Zm17.24-20.976-1.68 2.155c-.224.226-.56.34-.895.34-.224 0-.448-.114-.672-.227-.448-.34-.56-1.134-.224-1.587l1.68-2.155c.335-.453 1.119-.566 1.567-.226.447.453.56 1.134.223 1.7Zm-3.807 4.649-2.798 3.628c-.224.227-.56.454-.896.454-.224 0-.448-.114-.671-.227-.448-.34-.56-1.134-.224-1.588l2.798-3.628c.336-.453 1.12-.567 1.567-.226.56.453.56 1.133.224 1.587Zm-5.26 6.462-2.24 2.948c-.224.227-.56.454-.895.454-.224 0-.448-.114-.672-.227-.448-.34-.56-1.134-.224-1.587l2.239-2.948c.336-.454 1.12-.567 1.567-.227.56.454.56 1.134.224 1.587ZM55.97 13.582l-.223 7.936c0 .68-.56 1.134-1.12 1.134s-1.12-.567-1.12-1.134l.113-6.236-5.71 2.381c-.56.227-1.23 0-1.455-.567-.224-.567 0-1.247.56-1.474l7.276-2.947c.336-.114.784-.114 1.12.113.447.113.671.453.56.793Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="funding-progress_svg__a">
          <path fill="#fff" d="M0 0h56v56H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgFundingProgress
