import * as React from 'react'
import { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
  desc?: string
  descId?: string
  ariaHidden?: boolean
}
const SvgIllustrationPlant = ({
  title,
  titleId,
  desc,
  descId,
  ariaHidden,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 214 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    className={props.className}
    aria-hidden={ariaHidden}
    aria-labelledby={titleId}
    aria-describedby={descId}
    {...props}>
    {desc ? <desc id={descId}>{desc}</desc> : null}
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#Illustration_plant_svg__a)">
      <rect x={13.333} width={200} height={200} rx={100} fill="#EBF4F6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.48 110.544h214.958v2.764h-20.139v45.532h-1.514v-45.532h-13.584v45.532h-1.513v-45.532h-13.589v45.532h-1.513v-45.532h-13.583v45.532h-1.513v-45.532h-13.589v45.63l-1.513-.098v-45.532h-13.587v45.532l-1.514.098v-45.63h-13.584v45.532h-1.514v-45.532h-13.587v45.532l-1.513.098v-45.63H85.501v45.532l-1.514.098v-45.63H70.404v45.63h-1.513v-45.63H55.302v45.63H53.79v-45.63H40.2v45.63h-1.513v-45.63H25.103v45.63h-1.514v-45.63H11.48v-2.764Z"
        fill="#18808C"
        fillOpacity={0.15}
      />
      <path d="M391.852 157.236v50.88h-567.268v-22.13l66.565-28.75h500.703Z" fill="#CBE3E6" />
    </g>
    <path
      d="M149.667 42.917h-.417v34.167h34.167V42.917h-33.75Z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={0.833}
      strokeMiterlimit={10}
    />
    <path
      d="M168.879 67.103c4.249 0 7.693-3.787 7.693-8.458 0-4.672-3.444-8.459-7.693-8.459-4.248 0-7.692 3.787-7.692 8.459 0 4.67 3.444 8.458 7.692 8.458Z"
      fill="#FAA819"
    />
    <path
      d="M183 53.185v23.447h-33.333V60.608l8.438-6.352 10.387 7.28 10.548-12.098 3.96 3.747Z"
      fill="#3F5337"
    />
    <path
      d="M143.001 46.61c0 1.27-.134 2.571-.434 3.806-2.068 8.981-11.039 14.556-20.01 12.487a16.653 16.653 0 0 1-12.473-12.486c-2.034-8.981 3.569-17.928 12.54-19.998 8.971-2.037 17.909 3.572 19.976 12.553.267 1.201.401 2.403.401 3.639Z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={1.03}
      strokeMiterlimit={10}
    />
    <path
      d="M122.463 63.309h.001c9.193 2.12 18.388-3.592 20.509-12.797.308-1.27.444-2.605.444-3.901 0-1.266-.136-2.498-.41-3.73v-.003c-2.12-9.204-11.28-14.953-20.475-12.865h-.002c-9.195 2.122-14.937 11.292-12.852 20.496v.001a17.069 17.069 0 0 0 12.785 12.799Z"
      stroke="#fff"
      strokeWidth={0.833}
      strokeMiterlimit={10}
    />
    <path
      d="M125.693 35.561v11.05l4.135-5.508"
      stroke="#18214B"
      strokeWidth={1.03}
      strokeMiterlimit={10}
    />
    <path
      d="M142.569 50.417c-2.068 8.98-11.039 14.556-20.01 12.486a16.651 16.651 0 0 1-12.473-12.486h32.483Z"
      fill="#DC7833"
    />
    <path d="M143 68.334h-23.333v15H143v-15Z" fill="#FAA819" />
    <path d="M119.667 83.23 143 68.333V83.23h-23.333Z" fill="#DC7833" />
    <path d="m143 83.23-23.333-14.896V83.23H143Z" fill="#D9E1F2" />
    <path
      d="M143 83.202v.132h-23.333v-.132c0-5.445 5.207-9.835 11.666-9.868 6.46 0 11.667 4.422 11.667 9.868Z"
      fill="#18214B"
    />
    <path
      d="M119.667 67.917h-.417v15.729h24.167V67.917h-23.75Z"
      stroke="#fff"
      strokeWidth={0.833}
      strokeMiterlimit={10}
    />
    <path
      d="M108.807 95.06c-1.98 1.764-3.635 1.654-4.139 3.308-.54 1.764 1.116 2.647 1.655 5.955.144.771.72 4.41-1.043 7.056-1.764 2.647-4.176 1.875-5.184 4.191-.756 1.801.468 2.756.612 5.954.072 1.25.108 5.844-2.88 9.262-.431.478-3.24 3.602-7.63 3.529-3.456-.074-7.308-2.095-8.46-5.293-.792-2.205.252-3.492-.828-4.852-.108-.11-.216-.257-.324-.331-1.691-1.47-4.571.258-6.299-1.433-2.088-2.022-1.548-8.123 1.44-11.027 2.7-2.646 5.255-.551 7.847-3.307 1.8-1.912.936-3.272 3.744-8.38 1.692-3.088 2.735-3.933 3.095-4.19 2.232-1.691 3.564-.773 5.976-1.765 4.247-1.764 3.527-5.844 7.847-8.16 1.872-.992 4.283-1.47 6.011-1.102.72.11 1.368.514 1.836 1.102 1.476 2.022-.36 6.91-3.276 9.483Z"
      fill="#568482"
    />
    <path
      d="M43.147 110.791a52.314 52.314 0 0 1 3.276 3.676 81.333 81.333 0 0 1 10.547 17.642c2.196 5.366 3.707 11.027 4.535 16.76M83.86 120.422c-1.152 1.176-2.268 2.389-3.275 3.676a81.34 81.34 0 0 0-10.547 17.642c-2.196 5.366-3.708 11.026-4.536 16.76"
      stroke="#598885"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M108.807 95.062c-1.98 1.764-3.636 1.654-4.14 3.308-.54 1.764 1.116 2.646 1.656 5.954.144.772.72 4.41-1.044 7.057-1.764 2.646-4.175 1.874-5.183 4.19-.756 1.801.468 2.757.612 5.954.072 1.25.108 5.844-2.88 9.263-.432.477-3.24 3.602-7.631 3.528-3.456-.074-7.307-2.095-8.46-5.293-.791-2.205.253-3.491-.827-4.851-.108-.111-.216-.258-.324-.331 2.124-3.014 5.687-6.873 10.619-13.489 5.291-7.057 6.839-10.181 15.082-20.877 1.584-2.058 2.88-3.712 3.96-4.999.719.11 1.367.515 1.835 1.103 1.476 2.021-.36 6.91-3.275 9.483Z"
      fill="#73A2A5"
    />
    <path
      d="M63.378 168.166c-2.988-28.632.396-57.558 9.899-84.647"
      stroke="#598885"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M90.88 59.298c-1.547 2.757-4.355 2.536-4.967 5.072-.36 1.58.18 3.088.432 3.75.936 2.572 2.376 2.462 3.096 4.41 1.008 2.683-.432 6.395-2.484 8.38-1.548 1.507-2.375.956-3.311 2.205-1.656 2.206.108 4.925.216 9.704.036 1.837.216 8.49-3.708 11.688-1.944 1.58-5.075 2.462-7.631 1.544-2.232-.772-2.808-2.463-5.04-3.125-.252-.073-.468-.147-.72-.183-1.871-.331-2.231.441-3.923 0-2.808-.699-4.428-3.419-4.752-3.97-1.763-2.977-1.835-6.69-.827-9.483.971-2.72 2.231-2.573 3.311-5.734 1.044-3.087.072-3.932 1.044-5.733 1.692-3.088 5.04-1.507 8.063-4.411 3.06-2.977.9-5.918 4.14-10.586 2.483-3.602 4.031-2.205 7.235-6.395 2.772-3.639 2.628-5.99 5.363-7.498 1.332-.735 3.384-1.286 4.824-.661.252.11.504.257.72.44 2.087 1.912.684 7.388-1.08 10.586Z"
      fill="#568482"
    />
    <path
      d="M90.88 59.298c-1.547 2.756-4.355 2.536-4.967 5.072-.36 1.58.18 3.087.432 3.75.936 2.572 2.376 2.462 3.096 4.41 1.008 2.683-.432 6.395-2.484 8.38-1.548 1.507-2.376.956-3.312 2.205-1.655 2.206.108 4.925.216 9.704.036 1.837.216 8.49-3.707 11.688-1.944 1.58-5.076 2.462-7.631 1.543-2.232-.772-2.808-2.462-5.04-3.124 1.548-6.616 3.528-13.085 5.868-19.443 1.403-3.86 6.91-18.341 17.854-35.249.252.11.504.258.72.442 2.123 1.948.72 7.424-1.044 10.622Z"
      fill="#73A2A5"
    />
    <path
      d="M52.436 108.88a6.062 6.062 0 0 1-4.14 3.088c-1.44.257-2.483-.294-3.167-.258a.643.643 0 0 0-.54.258c-.684.772.648 2.242.396 4.19-.324 2.646-3.276 4.594-5.363 5.293-4.716 1.543-8.963-1.985-9.503-2.426-1.692-1.434-2.664-3.161-4.536-6.616-2.123-3.859-1.8-4.521-3.095-5.513-2.376-1.801-4.104-.037-7.631-1.985-1.008-.552-2.736-1.507-3.708-3.529-1.116-2.315-.18-3.822-1.26-4.851-1.368-1.287-3.312.698-5.76-.22-2.447-.92-4.859-4.559-3.923-7.94.072-.257.144-.515.252-.735.9-2.095 2.952-3.529 4.896-3.676 2.735-.183 3.24 2.353 6.587 2.867 3.563.552 4.391-2.095 7.63-1.543 3.24.55 3.42 3.381 7.236 5.292 3.348 1.654 4.464.11 9.287.883 1.26.183 8.963 1.506 13.642 7.938.828 1.103 4.428 6.028 2.7 9.483Z"
      fill="#568482"
    />
    <path
      d="M52.437 108.881a6.06 6.06 0 0 1-4.14 3.087c-1.44.258-2.483-.294-3.167-.257C35.987 102.596 20.473 93.407.459 87.6c.9-2.095 2.952-3.529 4.895-3.676 2.736-.184 3.24 2.352 6.588 2.867 3.563.551 4.391-2.095 7.63-1.544 3.24.552 3.42 3.382 7.236 5.293 3.348 1.654 4.463.11 9.287.882 1.26.184 8.963 1.507 13.642 7.94.828 1.139 4.428 6.064 2.7 9.519Z"
      fill="#73A2A5"
    />
    <path
      d="M43.544 138.8h44.563v24.957c0 4.705-3.744 8.527-8.352 8.527h-27.86c-4.608 0-8.352-3.822-8.352-8.527V138.8Z"
      fill="#CCBAB4"
    />
    <path
      d="m51.572 147.4 26.637-.257 7.343 40.945M53.478 147.365l-4.32 41.055"
      stroke="#568482"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="m36.13 188.566 7.163-39.107c.216-1.176 1.188-2.022 2.34-2.022l38.624-.367a2.44 2.44 0 0 1 2.375 1.837l10.151 39.071"
      stroke="#568482"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <defs>
      <clipPath id="Illustration_plant_svg__a">
        <rect x={13.333} width={200} height={200} rx={100} fill="#fff" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgIllustrationPlant
