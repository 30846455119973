import type { SVGRProps } from '@lib/types'
import {
  CampaignCommunity,
  CampaignEntity as Hospital,
  CampaignExperience,
  CampaignFoundation as Foundation,
  CampaignFundingProgress as Corporation,
  CampaignInnovation,
  CampaignLearning,
  Donors,
  EnvisionLogo,
  FundingProgress,
  PressGaney,
  CreateAccount,
  CandidPlatinumTransparency,
  RogHighPerformer,
  IllustrationPlant,
} from '@svgs/index'

// All svgs get mapped here.
const svgRenderMap: Record<string, Function> = {
  CampaignCommunity,
  Hospital,
  CampaignExperience,
  Foundation,
  Corporation,
  CampaignInnovation,
  CampaignLearning,
  Donors,
  EnvisionLogo,
  FundingProgress,
  PressGaney,
  CreateAccount,
  CandidPlatinumTransparency,
  RogHighPerformer,
  IllustrationPlant,
}

interface SvgRenderProps extends SVGRProps {
  svgType: string
  title?: string
  desc?: string
  className?: string
  ariaHidden?: boolean
}

export const SvgRenderer = ({ svgType, title, desc, className, ariaHidden }: SvgRenderProps) => {
  if (svgRenderMap[svgType] === undefined) {
    console.warn(`svgType '${svgType}' is not in rendererMap`)
    return null
  }

  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const svg = svgRenderMap[svgType]({ title, desc, className, ariaHidden })
  return svg
}

export default SvgRenderer
