import slugify from '@sindresorhus/slugify'
import * as React from 'react'
import { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
  desc?: string
  descId?: string
}
const SvgDonors = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  if (!titleId && title) titleId = slugify(title)

  if (!descId && desc) descId = slugify(desc)

  return (
    <svg
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className={props.className}
      aria-labelledby={titleId}
      aria-describedby={descId}
      {...props}>
      {desc && <desc id={descId}>{desc}</desc>}
      {title && <title id={titleId}>{title}</title>}
      <g clipPath="url(#donors_svg__a)">
        <path
          d="M27.877 23.546c4.606 0 8.403-3.74 8.403-8.273C36.28 10.739 32.483 7 27.877 7s-8.403 3.66-8.403 8.273c0 4.534 3.797 8.273 8.403 8.273Zm0-14.637c3.555 0 6.383 2.864 6.383 6.284s-2.908 6.284-6.383 6.284-6.383-2.863-6.383-6.284c0-3.42 2.908-6.284 6.383-6.284Zm17.292 19.886c3.798 0 6.949-3.102 6.949-6.84 0-3.74-3.151-6.841-6.95-6.841-3.797 0-6.948 3.102-6.948 6.84 0 3.819 3.151 6.841 6.949 6.841Zm0-11.693c2.747 0 4.929 2.148 4.929 4.852 0 2.705-2.182 4.853-4.93 4.853-2.747 0-4.928-2.148-4.928-4.852 0-2.705 2.262-4.853 4.929-4.853ZM10.424 28.796c3.797 0 6.949-3.103 6.949-6.841 0-3.74-3.152-6.841-6.95-6.841-3.797 0-6.948 3.102-6.948 6.84 0 3.819 3.15 6.841 6.949 6.841Zm0-11.694c2.747 0 4.928 2.148 4.928 4.852 0 2.705-2.181 4.853-4.928 4.853-2.748 0-4.93-2.148-4.93-4.852 0-2.705 2.263-4.853 4.93-4.853ZM20.039 49H51.148c2.667 0 4.848-2.068 4.848-4.693v-2.943c.162-6.205-5.01-11.296-11.393-11.296-1.94 0-3.798.477-5.495 1.352-2.343-3.818-6.544-6.363-11.312-6.363a13.396 13.396 0 0 0-11.231 6.125 11.384 11.384 0 0 0-5.091-1.193C5.171 29.989 0 35.079 0 41.284v2.943C0 46.852 2.182 49 4.848 49H20.04Zm21.009-5.25v-5.568c0-1.75-.323-3.42-.97-4.932a10.002 10.002 0 0 1 4.525-1.114c5.252 0 9.454 4.216 9.454 9.307v2.943c0 1.591-1.293 2.784-2.828 2.784H39.755c.808-.875 1.293-2.147 1.293-3.42ZM27.796 27.125c6.222 0 11.232 4.932 11.232 11.057v5.568c0 1.91-1.535 3.42-3.475 3.42H20.12c-1.94 0-3.475-1.51-3.475-3.42v-5.568c0-6.125 5.01-11.057 11.151-11.057ZM4.93 47.17c-1.535 0-2.828-1.272-2.828-2.784v-2.943c0-5.17 4.282-9.307 9.454-9.307 1.454 0 2.828.319 4.12.955-.727 1.59-1.05 3.261-1.05 5.09v5.569c0 1.273.485 2.545 1.212 3.42H4.93Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="donors_svg__a">
          <path fill="#fff" d="M0 0h56v56H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgDonors
