import slugify from '@sindresorhus/slugify'
import * as React from 'react'
import { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
  desc?: string
  descId?: string
}
const SvgCampaignFundingProgress = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  if (!titleId && title) titleId = slugify(title)

  if (!descId && desc) descId = slugify(desc)

  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className={props.className}
      aria-labelledby={titleId}
      aria-describedby={descId}
      {...props}>
      {desc && <desc id={descId}>{desc}</desc>}
      {title && <title id={titleId}>{title}</title>}
      <g clipPath="url(#campaign-funding-progress_svg__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.787 24.36c-.48 0-.96.161-1.439.323l-7.676-6.398c.08-.323.16-.567.16-.89 0-1.863-1.44-3.32-3.278-3.32-1.76 0-3.279 1.457-3.279 3.32 0 .729.24 1.376.56 1.862l-4.878 5.588c-.48-.324-1.039-.486-1.679-.486C1.44 24.36 0 25.817 0 27.68 0 29.543 1.44 31 3.278 31c1.84 0 3.279-1.458 3.279-3.32 0-.648-.16-1.215-.48-1.701l4.957-5.669c.48.243.96.405 1.44.405.96 0 1.759-.405 2.319-.972l7.276 5.993c-.4.567-.64 1.215-.64 1.944 0 1.862 1.44 3.32 3.278 3.32 1.84 0 3.279-1.458 3.279-3.32.08-1.863-1.36-3.32-3.199-3.32ZM3.358 29.38c-.96 0-1.679-.729-1.679-1.7 0-.972.72-1.701 1.68-1.701.479 0 .879.162 1.199.486l.08.08c.24.325.4.73.4 1.135 0 .89-.8 1.7-1.68 1.7Zm9.196-10.285a1.7 1.7 0 0 1-1.68-1.7c0-.972.72-1.701 1.68-1.701s1.679.729 1.679 1.7c0 .891-.72 1.701-1.68 1.701ZM24.787 29.38c-.96 0-1.679-.729-1.679-1.7 0-.972.72-1.701 1.68-1.701.959 0 1.678.729 1.678 1.7 0 .892-.72 1.701-1.679 1.701Zm12.314-14.982-1.2 1.539a.902.902 0 0 1-.639.243c-.16 0-.32-.081-.48-.162-.32-.243-.4-.81-.16-1.134l1.2-1.539c.24-.324.8-.405 1.12-.162.319.324.399.81.16 1.215Zm-2.718 3.32-2 2.592c-.16.162-.4.324-.64.324-.16 0-.319-.081-.479-.162-.32-.243-.4-.81-.16-1.134l2-2.591c.239-.324.799-.405 1.119-.162.4.324.4.81.16 1.133Zm-3.759 4.617-1.599 2.105c-.16.162-.4.324-.64.324-.16 0-.32-.08-.48-.162-.32-.243-.399-.81-.16-1.134l1.6-2.105c.24-.324.8-.405 1.12-.162.4.324.4.81.16 1.134ZM39.98 9.7l-.16 5.669c0 .486-.4.81-.8.81s-.8-.405-.8-.81l.08-4.454-4.077 1.7c-.4.162-.88 0-1.04-.404-.16-.405 0-.891.4-1.053l5.197-2.106c.24-.08.56-.08.8.081.32.081.48.324.4.567Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="campaign-funding-progress_svg__a">
          <path fill="#fff" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgCampaignFundingProgress
